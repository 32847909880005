<template>
  <app-table
    :loading="loading"
    :headings="headers"
    :items="items"
    :empty-text="emptyText"
  >
    <template v-slot:user_id="{ item }">
      <avatar size="36" with-name :user="item.user"></avatar>
    </template>

    <template v-slot:datetimeUTC="{ item }">
      <span>{{ formatDateToViewerTimezone(item.datetimeUTC) | format("MMM D, YYYY h:mm A") }}</span>
    </template>

    <template v-slot:created_at="{ item }">
      <span>{{ item.created_at | format("ll") }}</span>
    </template>

    <template v-slot:status="{ item }">
      <chip-in small v-if="item.status == 'approved'" text="Approved"></chip-in>
      <chip-out
        small
        v-else-if="item.status == 'pending'"
        text="Pending"
      ></chip-out>
      <chip-brb small v-else text="Denied"></chip-brb>
    </template>

    <template v-slot:handler="{ item }">
      <avatar
        v-if="item.denied_user"
        size="36"
        :user="item.denied_user"
        with-name
      ></avatar>
      <avatar
        v-else-if="item.approved_user"
        size="36"
        :user="item.approved_user"
        with-name
      ></avatar>
    </template>

    <template v-slot:actions="{ item }">
      <icon-list icon="mdi-dots-horizontal">
        <v-list dense>
          <v-list-item
            @click.stop="approvePTO(item)"
            v-if="item.status !== 'approved'"
          >
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left>mdi-clock-check-outline</v-icon> Approve PTO
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click.stop="denyPTO(item)"
            v-if="item.status !== 'denied'"
          >
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left>mdi-clock-remove-outline</v-icon> Deny PTO
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click.stop="deletePTO(item)"
            v-if="item.status !== 'approved'"
          >
            <v-list-item-title class="xtext-[#2E2E2E]">
              <v-icon color="#2E2E2E" left>mdi-clock-alert-outline</v-icon> Delete PTO
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </icon-list>
    </template>
  </app-table>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: {
    loading: { type: Boolean, default: false },
    loadingMore: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    emptyText: { type: String, default: "No data" },
  },
  data() {
    return {
      headers: [
        { text: "User", value: "user_id", sortable: true },
        { text: "PTO Date", value: "datetimeUTC", sortable: true },
        { text: "Total Time", value: "range", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Handler", value: "handler", sortable: true },
        { text: "Created", value: "created_at", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    approvePTO(item) {
      this.appConfirmation(
        `Approve this Paid Time Off?<br /> This will generate time logs stated on this PTO request.`,
        () => {
          this.$axios
            .put(`api/ptos`, { action: "approved", pto_id: item.id })
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.$emit("refetch", true);
            });
        }
      );
    },
    denyPTO(item) {
      this.appConfirmation(
        `Deny this Paid Time Off?<br /> This will also delete generated time logs attached to this PTO request.`,
        () => {
          this.$axios
            .put(`api/ptos`, { action: "denied", pto_id: item.id })
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.$emit("refetch", true);
            });
        }
      );
    },
    deletePTO(item) {
      this.appConfirmation(
        `Delete this Paid Time Off?<br /> This will also delete generated time logs attached to this PTO request.`,
        () => {
          this.$axios
            .put(`api/ptos`, { action: "deleted", pto_id: item.id })
            .then(({ data }) => {
              this.appToast(data.message, "success");
              this.$emit("refetch", true);
            });
        }
      );
    },
    formatDateToViewerTimezone(datetimeUTC) {
      if (!datetimeUTC) return "N/A";
      
      const userTimeZone = this.user?.tz || 'UTC';

      return moment.utc(datetimeUTC).tz(userTimeZone)
    }
  },
};
</script>

<style lang="scss" scoped></style>

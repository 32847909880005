var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',{attrs:{"loading":_vm.loading,"headings":_vm.headers,"items":_vm.items,"empty-text":_vm.emptyText},scopedSlots:_vm._u([{key:"user_id",fn:function(ref){
var item = ref.item;
return [_c('avatar',{attrs:{"size":"36","with-name":"","user":item.user}})]}},{key:"datetimeUTC",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(_vm.formatDateToViewerTimezone(item.datetimeUTC),"MMM D, YYYY h:mm A")))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.created_at,"ll")))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(item.status == 'approved')?_c('chip-in',{attrs:{"small":"","text":"Approved"}}):(item.status == 'pending')?_c('chip-out',{attrs:{"small":"","text":"Pending"}}):_c('chip-brb',{attrs:{"small":"","text":"Denied"}})]}},{key:"handler",fn:function(ref){
var item = ref.item;
return [(item.denied_user)?_c('avatar',{attrs:{"size":"36","user":item.denied_user,"with-name":""}}):(item.approved_user)?_c('avatar',{attrs:{"size":"36","user":item.approved_user,"with-name":""}}):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[(item.status !== 'approved')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.approvePTO(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-clock-check-outline")]),_vm._v(" Approve PTO ")],1)],1):_vm._e(),(item.status !== 'denied')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.denyPTO(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-clock-remove-outline")]),_vm._v(" Deny PTO ")],1)],1):_vm._e(),(item.status !== 'approved')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.deletePTO(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v("mdi-clock-alert-outline")]),_vm._v(" Delete PTO ")],1)],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }